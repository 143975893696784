










import { connectSocialController } from '@/modules/profile/viewmodels/connect-socical-controller'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class TwitterAuthenticationPage extends Vue {
  localData = localData
  connectSocialController = connectSocialController
  async mounted() {
    const access_token = this.$route.query.access_token as string
    const isConnectYoutube = localData.getConnectYoutubeFlag()
    if (access_token && access_token !== 'undefined') {
      if (isConnectYoutube) {
        await connectSocialController.handleConnectSocial('youtube', access_token)
        localData.setConnectYoutubeFlag(false)
      } else await walletStore.connectSocialAccount('google', access_token)
    }
    if (!isConnectYoutube) {
      this.$router.replace('/')
    }
  }
}
