import { appProvider } from '@/app-provider'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CONNECT_USER_SOCIALS } from '@/constants'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { action, computed } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class ConnectSocialController {
  constructor() {
    //
  }

  @asyncAction *handleConnectSocial(provider, accessToken) {
    try {
      loadingController.increaseRequest()
      const accessSecret = appProvider.router.currentRoute.query.access_secret as string
      let res
      if (accessSecret) res = yield apiService.users.connectSocial(provider, accessToken, accessSecret)
      else res = yield apiService.users.connectSocial(provider, accessToken)
      const data = res?.user?.data
      if (data && CONNECT_USER_SOCIALS.includes(provider)) {
        if (provider === 'youtube' && !data?.items?.length)
          snackController.error("You don't have any youtube channels from this account")
        else {
          yield apiService.profiles.updateSocialProfile({
            type: provider,
            data: res.user.data,
          })
          snackController.success(`Update the ${provider} social successfully`)
        }

        appProvider.router.push(`/profile/${walletStore.userProfile?.unique_id}/setting/customize-profile`)
        loadingController.decreaseRequest()
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @action getSocialLink(provider, youtubeFlag = false) {
    return `${process.env.VUE_APP_API_ENDPOINT}/connect/${provider}${youtubeFlag ? `?youtubeFlag=true` : ''}`
  }

  @computed get connectSocialLink() {
    return `${process.env.VUE_APP_API_ENDPOINT}/connect/discord`
  }

  @computed get connectTwitterLink() {
    return `${process.env.VUE_APP_API_ENDPOINT}/connect/twitter`
  }
}

export const connectSocialController = new ConnectSocialController()
